
import Vue from 'vue'
// import FooterVue from './components/Footer.vue'
import HeaderView from './components/HeaderView.vue'

export default Vue.extend({
  name: 'App',
  data: () => ({
    //
  }),
  components: { HeaderView }
  // components: { FooterVue }
})
