
import Vue from 'vue'
import CastingWorld from '../components/castingHome.vue'

export default Vue.extend({
  name: 'castingHome',

  components: {
    CastingWorld
  }
})
