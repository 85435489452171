<template>
    <!-- <body> -->
    <div>
        <div class="carousel">

            <div class="list">
                <div class="item">
                    <img :src=data.homeimg.fullposter>
                    <div class="content">
                        <div class="author">{{ data.homeimg.name }}</div>
                        <div class="title">{{ data.homeimg.title }}</div>
                        <div class="topic">{{ data.homeimg.event }}</div>
                        <div class="des">
                            <!-- lorem 50 -->
                            <v-card>
                            {{ data.homeimg.desc }}
                            </v-card>
                        </div>
                        <!-- <div v-if="data.homeimg.ispaid === true">
                            <v-btn color="green" @click="download(data.homeimg)">Watermark removed (Download)</v-btn>
                        </div>
                        <div v-else>
                            <v-btn color="pink" @click="pbuy(data.homeimg)">Remove Watermark (Download)</v-btn>
                        </div> -->
                    </div>
                </div>

            </div>

        </div>

        <v-row>
            <div class="thumbnail">
                <div v-for="item in data.thumbsimg" v-bind:key="item" class="pa-md-2">
                    <div class="item">
                        <img :src=item.thumb @click="eventDetails(item)">
                        <div class="content">
                            <v-card>
                            <div class="title" @click="eventDetails(item)">
                                {{ item.status }}
                            </div>
                            <div class="description" @click="eventDetails(item)">
                                {{ item.name }}
                            </div>
                            </v-card>
                            <div class="description" @click="eventDetails(item)">
                                {{ item.date }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

        <v-card>
            <template>
                <v-row>
                    <div class="thumbnail2">
                        <div v-for="item in data.thumbsimg2" v-bind:key="item" class="pa-md-2">
                            <div class="item">
                                <img :src=item.thumb @click="eventDetails(item)">

                            </div>

                        </div>
                    </div>

                </v-row>
            </template>
        </v-card>
        <div class="arrows">
            <div v-if="data.thumbsimg2">
                <button id="next" @click=back()>Prev</button>
                <button id="prev" @click=front()>Next</button>
            </div>
        </div>
        <div class="time"></div>
    </div>
    <!-- </body> -->
</template>

<script>
import { reactive, onMounted, require } from 'vue'
import router from '../router'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { collection, addDoc, where, query, doc, setDoc, updateDoc, getDocs } from 'firebase/firestore'
import { db } from '../main'

export default {
    name: 'GalleryHome',

    setup () {
        const data = reactive({
            id: '',
            homeimg: '',
            thumbsimg: '',
            detailEvent: '',
            dialog: false,
            isapplied: false,
            inprogress: ''
        })
        const auth = getAuth()
        // const VISH = 'http://localhost:8080'
        const VISH = 'http://vishravas.com'

        onMounted(async () => {
            // const qDoclist = query(collection(db, 'vishgall'))
            const qDoclist = query(collection(db, 'NewHome'), where('home', '==', true))
            const querySnapshot = await getDocs(qDoclist)
            data.thumbsimg = querySnapshot.docs.map(doc => doc.data())
            // data.thumbsimg2 = querySnapshot.docs.map(doc => doc.data())
            data.homeimg = data.thumbsimg[0]
            data.id = querySnapshot.docs.map(doc => doc.id)
            const qOrderlist = query(collection(db, 'vishorders'), where('customer', '==', localStorage.getItem('vishuser')), where('status', '==', 'inprogress'))
            const orderSnap = await getDocs(qOrderlist)
            data.inprogress = orderSnap.docs.map(doc => doc.data().productuid)
            const newlist = data.doclist.forEach(element => {
                if (data.inprogress.includes(element.refuuid)) {
                    element.ispaid = true
                }
            })
        })

        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid
                localStorage.setItem('vishuser', user.email)
            } else {
                router.push('/')
            }
        })

        const submit = async () => {
            signOut(auth).then(() => {
                router.push('/')
            }).catch((error) => {
                const errorMessage = error.message
            })
        }

        const home = async () => {
            router.push('/home')
        }

        const listclicked = async (item) => {
            console.log(item.refuuid)
            const colRef = collection(db, 'analytics')
            const date = new Date()
            setDoc(doc(colRef), {
                gal_id: item.refuuid,
                email: localStorage.getItem('vishuser'),
                date: date

            })
        }

        const download = async (item) => {
            const xhr = new XMLHttpRequest()

            xhr.responseType = 'blob'
            xhr.onload = () => {
                const urlCreator = window.URL || window.webkitURL
                const imageUrl = urlCreator.createObjectURL(xhr.response)
                const link = document.createElement('a')
                link.href = imageUrl
                link.setAttribute('download', item.name)
                link.setAttribute('target', 'new')
                document.body.appendChild(link)
                link.click()
            }
            xhr.open('GET', item.fullposter)
            xhr.send()
            const colRef = collection(db, 'vishorders')
            const qOrderlist = query(collection(db, 'vishorders'), where('productuid', '==', item.refuuid), where('status', '==', 'inprogress'))
            const orderSnap = await getDocs(qOrderlist)
            data.id = orderSnap.docs.map(doc => doc.id)
            updateDoc(doc(colRef, data.id[0]), {
                status: 'downloaded'
            })
        }

        const pbuy = async (item) => {
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const stripe = require('stripe')('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')

                    const session = await stripe.checkout.sessions.create({
                        line_items: [
                            {
                                price: item.price,
                                quantity: 1
                            }
                        ],
                        mode: 'payment',
                        customer_email: user.email,
                        client_reference_id: item.refuuid,
                        success_url: `${VISH}/gallery`,
                        cancel_url: `${VISH}/gallery`
                    })
                    window.location.assign(session.url)
                } else {
                    router.push('/')
                }
            })
        }

        const front = async () => {
            data.homeimg = data.thumbsimg2[1]
            const firstElement = data.thumbsimg2.shift()
            data.thumbsimg2.push(firstElement)
        }

        // const pupdate = async (item) => {
        //     data.display = item
        //     }

        const back = async () => {
            const lastElement = data.thumbsimg2.pop() // Remove the last element
            data.thumbsimg2.unshift(lastElement)
            data.homeimg = data.thumbsimg2[0]
        }

        const eventDetails = async (item) => {
            console.log(item)
            const qDoclist = query(collection(db, 'NewHome'), where('status', '==', item.status))
            const querySnapshot = await getDocs(qDoclist)
            data.thumbsimg2 = querySnapshot.docs.map(doc => doc.data())
            // data.thumbsimg2 = querySnapshot.docs.map(doc => doc.data())
            data.homeimg = data.thumbsimg2[0]
            console.log(data.homeimg)
        }
        return {
            data,
            home,
            submit,
            pbuy,
            download,
            listclicked,
            front,
            back,
            eventDetails
        }
    }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    /* background-color: #000; */
    color: #eee;
    font-family: Poppins;
    font-size: 12px;
}

a {
    text-decoration: none;
}

header {
    width: 1140px;
    max-width: 80%;
    margin: auto;
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
}

header a {
    color: #eee;
    margin-right: 40px;
}

/* carousel */
.carousel {
    height: 100vh;
    margin-top: -50px;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.carousel .list .item {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
}

.carousel .list .item img {
    width: 100%;
  height: 100%;
    /* width: 1920px;
    height: 100%; */
    object-fit: cover;
}

.carousel .list .item .content {
    position: absolute;
    top: 20%;
    width: 1140px;
    max-width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
}

.carousel .list .item .author {
    font-weight: bold;
    letter-spacing: 10px;
}

.carousel .list .item .title,
.carousel .list .item .topic {
    font-size: 5em;
    font-weight: bold;
    line-height: 1.3em;
}

.carousel .list .item .topic {
    color: #f1683a;
}

.carousel .list .item .buttons {
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
}

.carousel .list .item .buttons button {
    border: none;
    background-color: #eee;
    letter-spacing: 3px;
    font-family: Poppins;
    font-weight: 500;
}

.carousel .list .item .buttons button:nth-child(2) {
    background-color: transparent;
    border: 1px solid #fff;
    color: #eee;
}

/* thumbail */
.thumbnail {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
}

.thumbnail2 {
    position: absolute;
    bottom: 220px;
    left: 50%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
}

.thumbnail2 .item {
    width: 150px;
    height: 200px;
    flex-shrink: 0;
    position: relative;
}

.thumbnail2 .item {
    width: 100px;
    height: 150px;
    flex-shrink: 0;
    position: relative;
}

.thumbnail2 .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.thumbnail .item {
    width: 120px;
    height: 160px;
    flex-shrink: 0;
    position: relative;
}

.thumbnail .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.thumbnail .item .content {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.thumbnail .item .content .title {
    font-weight: 500;
}

.thumbnail .item .content .description {
    font-weight: 300;
}

/* arrows */
.arrows {
    position: absolute;
    top: 80%;
    right: 52%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.arrows button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #eee4;
    border: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
}

.arrows button:hover {
    background-color: #fff;
    color: #000;
}

/* animation */
.carousel .list .item:nth-child(1) {
    z-index: 1;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}

@keyframes showContent {
    to {
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.carousel .list .item:nth-child(1) .content .title {
    animation-delay: 1.2s !important;
}

.carousel .list .item:nth-child(1) .content .topic {
    animation-delay: 1.4s !important;
}

.carousel .list .item:nth-child(1) .content .des {
    animation-delay: 1.6s !important;
}

.carousel .list .item:nth-child(1) .content .buttons {
    animation-delay: 1.8s !important;
}

/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}

@keyframes showImage {
    to {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}

.carousel.prev .list .item img {
    z-index: 100;
}

@keyframes showThumbnail {
    from {
        width: 0;
        opacity: 0;
    }
}

.carousel.next .thumbnail {
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext {
    from {
        transform: translateX(150px);
    }
}

/* running time */

.carousel .time {
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 3px;
    background-color: #f1683a;
    left: 0;
    top: 0;
}

.carousel.next .time,
.carousel.prev .time {
    animation: runningTime 3s linear 1 forwards;
}

@keyframes runningTime {
    from {
        width: 100%
    }

    to {
        width: 0
    }
}

/* prev click */

.carousel.prev .list .item:nth-child(2) {
    z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}

@keyframes outFrame {
    to {
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.carousel.prev .thumbnail .item:nth-child(1) {
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}

.carousel.next .arrows button,
.carousel.prev .arrows button {
    pointer-events: none;
}

.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
    animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
    to {
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}

@media screen and (max-width: 678px) {
    .carousel .list .item .content {
        padding-right: 0;
    }

    .carousel .list .item .content .title {
        font-size: 30px;
    }
}
</style>

<!-- old code in commented state -->
<!--
<template>
    <v-app id="app">
        <v-content>
            <v-container fluid fill-height>
                <v-row class="pa-md-6">
                        <v-card max-width="100%" height="100%" class="d-flex justify-space-around mb-6 center" v-if="data.display.link">
                                <v-container v-if="data.projectpaid == false">
                                    <v-row dense>
                                        <v-col cols="20">
                                            <v-container>
                                                <h1>{{data.display.title}}</h1>
                                                <div v-if="data.promo">
                                                    <v-card max-width="100%" height="400">
                                                        <iframe :src=data.display.link width="100%" height="100%" frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                                    </v-card>
                                                </div>
                                                <div v-else>
                                                    <v-card max-width="100%" height="400">
                                                        <v-img :src=data.display.fullposter class="white--text align-end"
                                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%" width="100%">
                                                            <v-row><v-col cols="20">
                                                                    <h1>{{data.display.status}}</h1>
                                                                </v-col></v-row>
                                                            <v-row><v-col cols="20">{{data.display.desc}}</v-col></v-row>
                                                            <v-row></v-row>
                                                        </v-img>
                                                    </v-card>
                                                </div>

                                            </v-container>
                                            <v-btn @click=trailer()>Promo</v-btn>
                                            <v-btn @click=buyticket(data.display)>Buyticket</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container v-else>
                                    <v-row dense>
                                        <v-col cols="20">
                                            <v-container>
                                                <h1>{{data.display.title}}</h1>
                                                <div v-if="data.promo">
                                                    <v-card max-width="100%" height="400">
                                                        <iframe :src=data.display.link width="100%" height="100%" frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                                    </v-card>
                                                </div>
                                                <div v-else-if="data.film">
                                                        <v-card max-width="100%" height='400'>
                                                            <iframe :src=data.display.fullurl width="100%" height="400" frameborder="0"
                                                                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                                        </v-card>
                                                </div>
                                                <div v-else>
                                                    <v-card max-width="100%" height="400">
                                                        <v-img :src=data.display.fullposter class="white--text align-end"
                                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%" width="100%">
                                                            <v-row><v-col cols="20">
                                                                    <h1>{{data.display.status}}</h1>
                                                                </v-col></v-row>
                                                            <v-row><v-col cols="20">{{data.display.desc}}</v-col></v-row>
                                                            <v-row></v-row>
                                                        </v-img>
                                                    </v-card>
                                                </div>

                                            </v-container>
                                            <v-btn @click=fullength()>Film</v-btn>
                                            <v-btn @click=trailer()>Promo</v-btn>

                                        </v-col>
                                    </v-row>
                                </v-container>
                        </v-card>
                        <v-card max-width="100%" height="100%" class="d-flex justify-space-around mb-6 center" v-else>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="20">
                                        <v-container>
                                            <h1>{{data.display.title}}</h1>
                                            <v-card max-width="100%" height="400">
                                                <v-img :src=data.display.fullposter class="white--text align-end"
                                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%" width="100%">
                                                    <v-row><v-col cols="20">
                                                            <h1>{{data.display.status}}</h1>
                                                        </v-col></v-row>
                                                    <v-row><v-col cols="20">{{data.display.desc}}</v-col></v-row>
                                                    <v-row></v-row>
                                                </v-img>
                                            </v-card>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-row>
                            <template>
                                <v-card max-width="400" class="mx-auto">

                                    <v-container>
                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-hover v-slot="{ hover }">
                                                    <v-card color="#385F73" dark :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                                        <a href="">
                                                            <v-img :src=data.audilist[0].audition class="white--text align-end"
                                                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%" width="100%" @click=audition()>
                                                                <v-row><v-col cols="20">
                                                                        <h1>{{data.audilist[0].duration}}</h1>
                                                                    </v-col></v-row>
                                                                <v-row><v-col cols="20">{{data.audilist[0].desc}}</v-col></v-row>
                                                                <v-row></v-row>
                                                            </v-img>
                                                        </a>
                                                    </v-card>
                                                </v-hover>
                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </v-card>
                            </template>
                        </v-row>
                        <v-layout align-top justify-end>
                            <v-flex xs20 sm8 md4>
                                <v-row>
                                    <v-col xs="6" class="d-flex justify-end">
                                        <v-btn rounded color="primary" dark @click="profile()">
                                            {{data.username}}
                                            <v-chip>Profile</v-chip>

                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-flex>

                        </v-layout>
                </v-row>
            </v-container>

        </v-content>
        <template>
            <v-container>
                <v-row class="text-h5 white--text font-italic">Premiering</v-row>
                <v-row>
                    <div v-for="item in data.premierlist" v-bind:key="item.id" class="pa-md-2">
                        <v-card max-width="200" height="300">
                            <v-img :src=item.listposter class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="300px" width="200px" @click="pupdate(item)">
                            </v-img>
                        </v-card>

                    </div>
                </v-row>
                <v-row class="text-h5 white--text font-italic">Projected</v-row>
                <v-row>
                    <div v-for="item in data.doclist" v-bind:key="item.id" class="pa-md-2">
                        <v-card max-width="200" height="300">
                            <v-img :src=item.listposter class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="300px" width="200px" @click="pupdate(item)">
                            </v-img>
                        </v-card>
                    </div>
                </v-row>
        </v-container>
        </template>
        <v-container>
        <v-layout align-top justify-end>
                    <v-flex xs20 sm8 md4>
                        <v-row>
                            <v-col xs="6" class="d-flex justify-end">
                                <form @submit.prevent="submit">
                                    <v-btn rounded color="primary" dark type="submit">
                                        SignOut
                                    </v-btn>
                                </form>
                            </v-col>
                        </v-row>
                    </v-flex>
                </v-layout>
            </v-container>
    </v-app>
</template>

<script>
import { reactive, onMounted, ref, computed } from 'vue'
import router from '../router'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { collection, addDoc, where, and, query, doc, setDoc, getDoc, getDocs } from 'firebase/firestore'
import { db } from '../main'
import store from '@/store/index.ts'

export default {
  name: 'vishHome',
  computed: { currentuser () { return this.$store.getters.getUser } },

  setup () {
    const data = reactive({
      submit: '',
      doclist: '',
      premierlist: '',
      audilist: '',
      display: '',
      username: '',
      currentuser: '',
      promo: true,
      projectpaid: false,
      film: false

    })
    const auth = getAuth()

    onMounted(async () => {
      const qDoclist = query(collection(db, 'projects'), where('status', '==', 'Projected'))
      const qPremierlist = query(collection(db, 'projects'), where('status', '==', 'Premiering'))
      const qProgresslist = query(collection(db, 'projects'), where('status', '==', 'InProgress'))

      const querySnapshot = await getDocs(qDoclist)
      data.doclist = querySnapshot.docs.map(doc => doc.data())
      const queryPlist = await getDocs(qPremierlist)
      data.premierlist = queryPlist.docs.map(doc => doc.data())
      data.display = data.premierlist[0]
      const queryFlist = await getDocs(qProgresslist)
      data.audilist = queryFlist.docs.map(doc => doc.data())
      const qOrderlist = query(collection(db, 'vishorder'), where('email', '==', localStorage.getItem('vishuser')), where('project', '==', data.display.title))
      const orderSnap = await getDocs(qOrderlist)
      if (orderSnap.docs.map(doc => doc.data()).length !== 0) { data.projectpaid = true } else { data.projectpaid = false }
    })

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        data.username = user.displayName
        store.commit('setUser', { value: user.email })
        localStorage.setItem('vishuser', user.email)
        // ...
      } else {
        router.push('/')
      }
    })

    const submit = async () => {
      signOut(auth).then(() => {
        router.push('/')
      }).catch((error) => {
        const errorMessage = error.message
      })
    }

    const pupdate = async (item) => {
      data.display = item
    }

    const profile = async () => {
      router.push('/profile')
    }

    const audition = async () => {
      router.push('/casting')
    }

    const trailer = async () => {
      data.film = false
      data.promo = true
    }

    const fullength = async (item) => {
      data.promo = false
      data.film = true
    }

    const buyticket = async (item) => {
      const colRef = collection(db, 'vishorder')
      const uniqueid = localStorage.getItem('vishuser') + '_' + item.title
      setDoc(doc(colRef, uniqueid), {
        project: item.title,
        email: localStorage.getItem('vishuser')

      })
      data.projectpaid = true
    }

    return {
      data,
      submit,
      pupdate,
      profile,
      audition,
      trailer,
      fullength,
      buyticket
    }
  }
}
</script>

<style>

</style> -->
