
import Vue from 'vue'
import vishHome from '../components/vishaHome.vue'

export default Vue.extend({
  name: 'Home',

  components: {
    // HelloWorld
    vishHome
  }
})
