<template>
    <v-container>
      <v-app-bar
        color="teal-darken-4"
        image="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:image>
          <v-img
            gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <template v-slot:prepend>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </template>

        <router-link color="white" to="/home">
          Home
        </router-link>

        <router-link color="white" to="/gallery">
          Gallery
        </router-link>

        <div v-if="data.signInuser">
          <router-link color="white" to="/casting">
            Audition
          </router-link>
        </div>

        <!-- <div v-if="data.signInuser">
          <router-link color="white" to="">
            <v-btn >Subscribe</v-btn>
          </router-link>
        </div> -->

        <div v-if="data.signInuser">
          <router-link color="white" to="/profile">
          {{ data.signInuser }}
        </router-link>
        </div>

        <v-spacer></v-spacer>

        <div v-if="data.signInuser" @click='signout'><v-btn >SignOut</v-btn></div>

      </v-app-bar>
    </v-container>
  </template>

<script>
import router from '../router'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { reactive } from 'vue'
export default {
  name: 'HeaderView',
  // data: () => ({
  // })
  setup () {
    const data = reactive({
      signInuser: ''

    })

    const auth = getAuth()
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        data.signInuser = user.displayName
      } else {
        router.push('/')
      }
    })

    const signout = async () => {
      signOut(auth).then(() => {
        data.signInuser = ''
        localStorage.removeItem('vishuser')
        router.push('/')
      }).catch((error) => {
        const errorMessage = error.message
      })
    }

    return {
      data,
      signout
    }
}
}
</script>

<style scoped>
.custom-button {
  border-radius: 50px; /* Adjust border radius for a circular button */
  padding: 20px 40px; /* Adjust padding for a larger button */
  font-size: 20px; /* Adjust font size for text */
  font-weight: bold; /* Make text bold */
  text-transform: uppercase; /* Convert text to uppercase */
  background-color: #ff5722; /* Custom background color */
  color: #ffffff; /* Custom text color */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}
</style>
