
<template>
  <!-- <body> -->
  <div>
    <div class="carousel">

      <div class="list">
        <div class="item">
          <img :src=data.homeimg.audition>
          <div class="content">
            <v-chip>
            <div class="author">{{ data.homeimg.title }}</div>
          </v-chip>
            <!-- <div class="title">{{ data.homeimg.venue }}</div>
            <div class="topic">{{ data.homeimg.event }}</div> -->
            <div class="des">
              <!-- lorem 50 -->
              <v-card class="pa-md-2 mx-lg-auto">
                  <v-card  color="green" text-color="white">{{data.homeimg.desc}}</v-card>
              </v-card>
            </div>
            <div>
              <v-card color="#385F73" max-width="100%" height="100%" class="d-flex justify-space-around mb-6 center">
            <v-container>
              <v-row dense>
                <v-col cols="20">
                  <v-container>
                    <h1>Audition</h1>

                    <v-card max-width="100%" height="100%" class="mt-2 mb-2">
                      <v-container>
                        <v-row dense>
                          <v-col cols="12">
                            <v-card color="#385F73" dark>
                              <div v-for="cast in data.homeimg.casting" v-bind:key="cast.id">

                                <v-card> <v-card class="ma-2" color="pink" label text-color="white" max-width="100%" height="100%">
                                  <v-row>
                                    <v-card class="ma-2"><v-h4 class="ma-2">{{cast.name}}</v-h4></v-card>
                                    <v-card class="ma-2"><v-h4 class="ma-2"></v-h4>Age:{{cast.age}}</v-card>
                                    <v-card class="ma-2"><v-h4 class="ma-2"></v-h4>Gender:{{cast.gender}}</v-card>
                                    <v-card class="ma-2"><v-h4 class="ma-2"></v-h4>ethnicity:{{cast.ethnicity}}</v-card>
                                    <v-btn color="#385F73" @click=showdetails(cast)>ShowMore</v-btn>
                                  </v-row>

                                  </v-card>

                                </v-card>

                              </div>
                              <div v-if="data.dialog===true">
                                <v-row>
                                  <v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.name}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.description}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.age}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.gender}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.ethnicity}}
                                    </v-col>
                                      <div v-if="data.isapplied === false">
                                      <v-col class="d-flex justify-end" cols="12" sm="12"  @click=apply(data.showdetails)>
                                        <v-btn>
                                        Apply
                                      </v-btn>
                                    </v-col>
                                    </div>
                                    <div v-else>
                                      <v-chip class="ma-2" color="green" text-color="white">Applied</v-chip>
                                      <v-chip class="ma-2" color="green" text-color="white">Our team will contact you for the next step.</v-chip>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-btn color="pink" @click=close()>Close</v-btn>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import router from '../router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { collection, where, query, doc, setDoc, getDocs, getDoc } from 'firebase/firestore'
import { db } from '../main'

export default {
  name: 'GalleryHome',

  setup () {
    const data = reactive({
      id: '',
      homeimg: '',
      thumbsimg: '',
      dialog: false,
      isapplied: false
    })
    const auth = getAuth()
    // const VISH = 'http://localhost:8080'
    // const VISH = 'http://vishravas.com'
    const VISH = 'http://11raw.com'

    onMounted(async () => {
      const qDoclist = query(collection(db, 'casting'), where('status', '==', 'open'))
      const querySnapshot = await getDocs(qDoclist)
      data.thumbsimg = querySnapshot.docs.map(doc => doc.data())
      data.id = querySnapshot.docs.map(doc => doc.id)
      data.homeimg = data.thumbsimg[0]
    })

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid
      } else {
        router.push('/')
      }
    })

    const showdetails = async (item) => {
      const auth = getAuth()
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          if (data.dialog === true) {
            data.dialog = false
          }
          data.dialog = true

          data.showdetails = item
          // const email = localStorage.getItem('vishuser')
          // const colRef = collection(db, 'applicants')
          const uniqueid = user.uid + '_' + item.name + '_' + data.id
          const qDoclist = doc(db, 'applicants', uniqueid)
          try {
            const querySnapshot = await getDoc(qDoclist)
            if (querySnapshot.exists()) {
              data.isapplied = true
            } else { data.isapplied = false }
          } catch (error) {
            data.isapplied = false
          }
        }
      })
    }
    const close = async () => {
      data.dialog = false
      data.showdetails = ''
    }

    const apply = async (role) => {
      data.isapplied = true
      const auth = getAuth()
      onAuthStateChanged(auth, async (user) => {
        if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid
        // ...
          const email = localStorage.getItem('vishuser')
          const colRef = collection(db, 'applicants')
          data.isapplied = false
          const uniqueid = user.uid + '_' + role.name + '_' + data.id
          setDoc(doc(colRef, uniqueid), {
            project: data.id,
            role: role.name,
            applicant: doc(db, 'profile', email),
            id: user.uid
          })
          data.dialog = false
        } else {
          router.push('/')
        }
      })
    }

    return {
      data,
      showdetails,
      close,
      apply
    }
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  /* background-color: #000; */
  color: #eee;
  font-family: Poppins;
  font-size: 12px;
}

a {
  text-decoration: none;
}

header {
  width: 1140px;
  max-width: 80%;
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
}

header a {
  color: #eee;
  margin-right: 40px;
}

/* carousel */
.carousel {
  height: 100vh;
  margin-top: -50px;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.carousel .list .item {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}

.carousel .list .item img {
  /* width: 100%;
height: 100%; */
  width: 1920px;
  height: 100%;
  object-fit: cover;
}

.carousel .list .item .content {
  position: absolute;
  top: 20%;
  width: 1140px;
  max-width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 30%;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
}

.carousel .list .item .author {
  font-weight: bold;
  letter-spacing: 10px;
}

.carousel .list .item .title,
.carousel .list .item .topic {
  font-size: 5em;
  font-weight: bold;
  line-height: 1.3em;
}

.carousel .list .item .topic {
  color: #f1683a;
}

.carousel .list .item .buttons {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 20px;
}

.carousel .list .item .buttons button {
  border: none;
  background-color: #eee;
  letter-spacing: 3px;
  font-family: Poppins;
  font-weight: 500;
}

.carousel .list .item .buttons button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}

/* thumbail */
.thumbnail {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;
}

.thumbnail2 {
  position: absolute;
  bottom: 220px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;
}

.thumbnail2 .item {
  width: 150px;
  height: 200px;
  flex-shrink: 0;
  position: relative;
}

.thumbnail2 .item {
  width: 100px;
  height: 150px;
  flex-shrink: 0;
  position: relative;
}

.thumbnail2 .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.thumbnail .item {
  width: 120px;
  height: 160px;
  flex-shrink: 0;
  position: relative;
}

.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.thumbnail .item .content {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.thumbnail .item .content .title {
  font-weight: 500;
}

.thumbnail .item .content .description {
  font-weight: 300;
}

/* arrows */
.arrows {
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrows button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee4;
  border: none;
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  transition: .5s;
}

.arrows button:hover {
  background-color: #fff;
  color: #000;
}

/* animation */
.carousel .list .item:nth-child(1) {
  z-index: 1;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons {
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent .5s 1s linear 1 forwards;
}

@keyframes showContent {
  to {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.carousel .list .item:nth-child(1) .content .title {
  animation-delay: 1.2s !important;
}

.carousel .list .item:nth-child(1) .content .topic {
  animation-delay: 1.4s !important;
}

.carousel .list .item:nth-child(1) .content .des {
  animation-delay: 1.6s !important;
}

.carousel .list .item:nth-child(1) .content .buttons {
  animation-delay: 1.8s !important;
}

/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  border-radius: 30px;
  animation: showImage .5s linear 1 forwards;
}

@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
  overflow: hidden;
  animation: showThumbnail .5s linear 1 forwards;
}

.carousel.prev .list .item img {
  z-index: 100;
}

@keyframes showThumbnail {
  from {
    width: 0;
    opacity: 0;
  }
}

.carousel.next .thumbnail {
  animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext {
  from {
    transform: translateX(150px);
  }
}

/* running time */

.carousel .time {
  position: absolute;
  z-index: 1000;
  width: 0%;
  height: 3px;
  background-color: #f1683a;
  left: 0;
  top: 0;
}

.carousel.next .time,
.carousel.prev .time {
  animation: runningTime 3s linear 1 forwards;
}

@keyframes runningTime {
  from {
    width: 100%
  }

  to {
    width: 0
  }
}

/* prev click */

.carousel.prev .list .item:nth-child(2) {
  z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes outFrame {
  to {
    width: 150px;
    height: 220px;
    bottom: 50px;
    left: 50%;
    border-radius: 20px;
  }
}

.carousel.prev .thumbnail .item:nth-child(1) {
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail .5s linear 1 forwards;
}

.carousel.next .arrows button,
.carousel.prev .arrows button {
  pointer-events: none;
}

.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
  animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
  to {
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}

@media screen and (max-width: 678px) {
  .carousel .list .item .content {
    padding-right: 0;
  }

  .carousel .list .item .content .title {
    font-size: 30px;
  }
}
</style>
<!-- old code -->
<!-- <template>
  <v-app id="app">
    <v-content>
      <v-container fluid fill-height>
        <v-row class="pa-md-6">
          <v-card color="#385F73" max-width="100%" height="100%" class="d-flex justify-space-around mb-6 center">
            <v-container>
              <v-row dense>
                <v-col cols="20">
                  <v-container>
                    <h1>Audition</h1>
                    <v-card max-width="100%" height="100%" class="mt-2 mb-2">
                      <v-container>
                        <v-row dense>
                          <v-col cols="12">
                            <v-card color="#385F73" dark>
                              <div v-for="cast in data.doclist[0].casting" v-bind:key="cast.id">

                                <v-card> <v-card class="ma-2" color="pink" label text-color="white" max-width="100%" height="100%">
                                  <v-row>
                                    <v-card class="ma-2"><v-h4 class="ma-2">{{cast.name}}</v-h4></v-card>
                                    <v-card class="ma-2"><v-h4 class="ma-2"></v-h4>Age:{{cast.age}}</v-card>
                                    <v-card class="ma-2"><v-h4 class="ma-2"></v-h4>Gender:{{cast.gender}}</v-card>
                                    <v-card class="ma-2"><v-h4 class="ma-2"></v-h4>ethnicity:{{cast.ethnicity}}</v-card>
                                    <v-btn color="#385F73" @click=showdetails(cast)>ShowMore</v-btn>
                                  </v-row>

                                  </v-card></v-card>

                              </div>
                              <div v-if="data.dialog===true">
                                <v-row>
                                  <v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.name}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.description}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.age}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.gender}}
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                      {{data.showdetails.ethnicity}}
                                    </v-col>
                                      <div v-if="data.isapplied === false">
                                      <v-col class="d-flex justify-end" cols="12" sm="12"  @click=apply(data.showdetails)>
                                        <v-btn>
                                        Apply
                                      </v-btn>
                                    </v-col>
                                    </div>
                                    <div v-else>
                                      <v-chip class="ma-2" color="green" text-color="white">Applied</v-chip>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-btn color="pink" @click=close()>Close</v-btn>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-row>
            <template>
              <v-card max-width="400" max-height="630px" class="mx-auto">
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <v-card max-width="100%" height="100%">
                        <div v-if="data.doclist[0].audition">
                          <v-img :src="data.doclist[0].audition" class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%" width="100%">

                          </v-img>
                        </div>
                        <div v-else>
                          <v-img src='../assets/ComingSoon.jpg' class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%" width="100%">

                          </v-img>
                        </div>

                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </template>
          </v-row>
        </v-row>
      </v-container>

    </v-content>

  </v-app>
</template>

<script>
import { reactive, onMounted } from 'vue'
import router from '../router'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { collection, addDoc, where, query, doc, setDoc, updateDoc, getDocs, getDoc } from 'firebase/firestore'
import { db } from '../main'

export default {
  name: 'CastingWorld',

  setup () {
    const data = reactive({
      id: '',
      doclist: '',
      dialog: false,
      isapplied: false
    })
    const auth = getAuth()

    const GenderOptions = ['Male', 'Female']
    const weekendOptions = ['Yes', 'No']

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid
        // ...
        const qDoclist = query(collection(db, 'casting'), where('status', '==', 'open'))

        const querySnapshot = await getDocs(qDoclist)
        data.doclist = querySnapshot.docs.map(doc => doc.data())
        data.id = querySnapshot.docs.map(doc => doc.id)
      } else {
        router.push('/')
      }
    })

    const submit = async () => {
      signOut(auth).then(() => {
        router.push('/')
      }).catch((error) => {
        const errorMessage = error.message
      })
    }

    const showdetails = async (item) => {
      const auth = getAuth()
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          if (data.dialog === true) {
            data.dialog = false
          }
          data.dialog = true
          data.showdetails = item
          const email = localStorage.getItem('vishuser')
          const colRef = collection(db, 'applicants')
          const uniqueid = user.uid + '_' + item.name + '_' + data.id[0]
          const qDoclist = doc(db, 'applicants', uniqueid)
          try {
            const querySnapshot = await getDoc(qDoclist)
            if (querySnapshot.exists()) {
              data.isapplied = true
            } else { data.isapplied = false }
          } catch (error) {
            data.isapplied = false
          }
        }
      })
    }
    const close = async () => {
      data.dialog = false
      data.showdetails = ''
    }

    const apply = async (role) => {
      data.isapplied = true
      const auth = getAuth()
      onAuthStateChanged(auth, async (user) => {
        if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid
        // ...
          const email = localStorage.getItem('vishuser')
          const colRef = collection(db, 'applicants')
          data.isapplied = false
          const uniqueid = user.uid + '_' + role.name + '_' + data.id[0]
          setDoc(doc(colRef, uniqueid), {
            project: data.id[0],
            role: role.name,
            applicant: doc(db, 'profile', email),
            id: user.uid
          })
          data.dialog = false
        } else {
          router.push('/')
        }
      })
    }

    const home = async () => {
      router.push('/home')
    }

    return {
      data,
      home,
      submit,
      showdetails,
      close,
      apply
    }
  }
}
</script>

<style>

</style> -->
