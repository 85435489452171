<template>
    <front-page />
</template>

<script>
// @ is an alias to /src
import FrontPage from '@/components/FrontPage.vue'

export default {
  name: 'FrontView',
  components: {
    FrontPage
  }
}
</script>
